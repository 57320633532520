<template>
  <div class="d-flex w-100 h-100 flex-column">
    <Gallery/>
    <Footer/>
  </div>
</template>

<script>
import Footer from '../components/footer/Footer'
import Gallery from '../components/dashboard/Gallery'

export default {
  name: 'GalleryVue',
  components: {
    Gallery,
    Footer
  }
}
</script>