import {
  AUTH_REQUEST_EMAIL_PASSWORD,
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  USER_REQUEST,
  CREATE_NEW_USER_REQUEST
} from "../actions/auth";
import firebase from "firebase";
import apiService from "../../service/APIService";

const state = {
  idUser: localStorage.getItem("id-user") || "",
  displayName: localStorage.getItem("display-name") || "",
  token: localStorage.getItem("user-token") || "",
  refreshToken: localStorage.getItem("user-refresh-token") || "",
  status: "", // Status request
};

const getters = {
  idUser: (state) => state.idUser,
  nameUser: (state) => state.displayName,
  token: (state) => state.token,
  refreshToken: (state) => state.refreshToken,
  isAuthenticated: (state) => !!state.token,
  authStatus: (state) => state.status
};

const actions = {
  [AUTH_REQUEST_EMAIL_PASSWORD]: ({ commit, dispatch }, {email, password}) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);

      // Connexion via Firebase email/password
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        // Connexion/Create Account Firebase OK - Call WS getUSer
        .then(resultFirebase => dispatch(USER_REQUEST, {resultFirebase}))
        .then(result => {
          let resultUser = result.resultUser;
          let resultFirebase = result.resultFirebase;

          // Account not created in DB
          if (resultUser.message != null && resultUser.message == "NO_USER") {
            dispatch(CREATE_NEW_USER_REQUEST, {resultFirebase})
            .then(resultCreateAccount => {
              resultUser = resultCreateAccount.data;

              // Creation of the new account OK
              commit(AUTH_SUCCESS, {
                resultUser,
                resultFirebase,
              });
              
              resolve(resultUser);
            })
            .catch((err) => {
              commit(AUTH_ERROR, err);
              reject(err);
            });
          }
          // Account already created
          else {
            commit(AUTH_SUCCESS, {
              resultUser,
              resultFirebase,
            });
            
            resolve(resultUser);
          }
        })
        .catch((err) => {
          commit(AUTH_ERROR, err);
          reject(err);
        });
    });
  },
  [AUTH_REQUEST]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);

      var provider = new firebase.auth.GoogleAuthProvider();

      // Connexion via Firebase
      firebase
        .auth()
        .signInWithPopup(provider)
        // Connexion/Create Account Firebase OK - Call WS getUSer
        .then(resultFirebase => dispatch(USER_REQUEST, {resultFirebase}))
        .then(result => {
          let resultUser = result.resultUser;
          let resultFirebase = result.resultFirebase;

          // Account not created in DB
          if (result.resultUser.message != null && result.resultUser.message == "NO_USER") {
            dispatch(CREATE_NEW_USER_REQUEST, {resultFirebase})
            .then(resultCreateAccount => {
              resultUser = resultCreateAccount.data;

              // Creation of the new account OK
              commit(AUTH_SUCCESS, {
                resultUser,
                resultFirebase,
              });
              
              resolve(resultUser);
            })
            .catch((err) => {
              commit(AUTH_ERROR, err);
              reject(err);
            });
          }
          // Account already created
          else {
            commit(AUTH_SUCCESS, {
              resultUser,
              resultFirebase,
            });
            
            resolve(resultUser);
          }
        })
        .catch((err) => {
          commit(AUTH_ERROR, err);
          reject(err);
        });
    });
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    return new Promise((resolve) => {

      firebase.auth().signOut();

      commit(AUTH_LOGOUT);
      resolve();
    });
  },
  /**
   * 
   * WS Get User - User account created or no
   * 
   */
  [USER_REQUEST]: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
        let resultFirebase = payload.resultFirebase;

        apiService.getUser(resultFirebase.user.uid, state.refreshToken)
        .then((resultUser) => resolve({resultUser: resultUser, resultFirebase: resultFirebase}))
        .catch((err) => {
          // No account in DB - Creation
          if (err.response != null && err.response.status == 401) {
            resolve({resultUser: err.response.data, resultFirebase: resultFirebase})
          }
          else {
            reject(err);
          }
        });
    });
  },
  /**
   * 
   * WS Create new User
   * 
   */
  [CREATE_NEW_USER_REQUEST]: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      apiService.newUser({idUserFirebase: payload.resultFirebase.user.uid})
        .then((resultUser) => resolve(resultUser))
        .catch((err) => reject(err));
    });
  },
};

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = "loading";
  },
  [AUTH_SUCCESS]: (state, { resultUser, resultFirebase }) => {
    state.status = "success";
    
    state.token = resultUser.token;
    state.refreshToken = resultUser.refreshToken;
    state.idUser = resultUser.user._id;
    state.displayName = resultFirebase.user.displayName;

    localStorage.setItem("id-user", resultUser.user._id);
    localStorage.setItem("display-name", resultFirebase.user.displayName);
    localStorage.setItem("user-token", resultUser.token);
    localStorage.setItem("user-refresh-token", resultUser.refreshToken);
  },
  [AUTH_ERROR]: (state) => {
    state.status = "error";
    
    localStorage.removeItem("id-user");
    localStorage.removeItem("display-name");
    localStorage.removeItem("user-token");
    localStorage.removeItem("user-refresh-token");
  },
  [AUTH_LOGOUT]: (state) => {
    state.token = "";

    localStorage.removeItem("id-user");
    localStorage.removeItem("display-name");
    localStorage.removeItem("user-token");
    localStorage.removeItem("user-refresh-token");
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
