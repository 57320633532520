const state = {
  navigation: {
    show: true,
    hideNavbarFooterApp: false
  },
};

const getters = {
  isDashboardShow: (state) => state.navigation.show,
  hideNavbarFooterApp: (state) => state.navigation.hideNavbarFooterApp
};

const mutations = {
  SHOWNAV(state) {
    state.navigation.show = true;
  },
  HIDENAV(state) {
    state.navigation.show = false;
  },
  ISAPP(state) {
    state.navigation.hideNavbarFooterApp = true;
  }
};

export default {
  state,
  mutations,
  getters
}; 
