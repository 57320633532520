<template>
  <div class="d-flex w-100 h-100 flex-column">
    <h1>This is an about page</h1>
    <Footer/>
  </div>
</template>

<script>
import Footer from '../components/footer/Footer'

export default {
  name: 'About',
  components: {
    Footer
  }
}
</script>