<template>
    <footer class="text-center mt-auto" v-show="!hideNavbarFooterApp">
        <p>
            {{ $t('footer-screen.name') }}
            <router-link id="link-footer" to="/terms">{{ $t('footer-screen.terms') }}</router-link> |
            <router-link id="link-footer" to="/privacy-policy">{{ $t('footer-screen.privacy') }}</router-link>
        </p>
    </footer>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  computed: {
    ...mapGetters(["hideNavbarFooterApp"])
  }
};
</script>