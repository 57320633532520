<template>
  <b-container>
    <b-row align-v="center">
      <b-col cols="12" md="5" lg="6" order-md="2" class="p-4">
        <b-img
          center
          :src="images.presentation"
          fluid
          alt="presentation"
        ></b-img>
      </b-col>

      <b-col cols="12" md="7" lg="6" order-md="1">
        <!-- Heading -->
        <h2 class="text-center">
         {{ $t('home-screen.description') }}
        </h2>

        <!-- Text 
            <p class="lead text-md-left text-muted mb-6 mb-lg-8">
              Gratuit avec de nombreuses fonctionnalités.
            </p>-->

            
        <lottie-animation
          path="animations/loading.json"
          :width="150"
          :height="150"
        />

        <!-- Buttons -->
        <div class="text-center mb-4">
          <b-button v-on:click="redirect_to_login" variant="link">
            <b-img
              :src="loadImgPlay()"
              fluid
              alt="Download app"
              style="height:100px"
            ></b-img>
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  components: {
    LottieAnimation,
  },
  data() {
    return {
      images: {
        imgPlayStoreFR:  require('@/assets/images/google-play-badge-fr.png'),
        imgPlayStoreEN: require('@/assets/images/google-play-badge-default.png'),
        presentation: require("@/assets/images/decoupe-telephone.png")
      }
    };
  },
  methods: {
    redirect_to_login() {
      window.open("https://play.google.com/store/apps/details?id=com.mitch.camera", "_blank");
    },
    loadImgPlay() {
      if (this.$i18n.t('images.lang') == "FR") {
        return this.images.imgPlayStoreFR
      }
      else {
        return this.images.imgPlayStoreEN
      }
    }
  },
  computed: {
    ...mapGetters(["isAuthenticated", "authStatus"]),
    loading: function() {
      return this.authStatus === "loading" && !this.isAuthenticated;
    },
  },
};
</script>
