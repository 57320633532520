import io from "socket.io-client";

var config = {
    peerConnectionConfig: { 
        iceServers: [
            {urls: "turn:turn-server-mobile.com:80", credential: "my_password", username: "my_username"}
        ]
    },
    peerConnectionConstraints: {
        optional: [
            {"DtlsSrtpKeyAgreement": true}
        ]
    }
},
currentPeer,        // Current peer
sendChannel,        // RTCDataChannel for the local (sender)
receiveChannel,     // RTCDataChannel for the remote (receiver)
mySocket,           // My socket IO
remoteVideo,        // Video
obs;                // Observable

export default class PeerConnectionClient {
    constructor(socketIO, videoRemote, remoteId, observable) 
    {
        mySocket = socketIO;
        remoteVideo = videoRemote;
        obs = observable;

        remoteVideo.addEventListener('loadedmetadata', function() {
         console.log(`Remote video videoWidth: ${this.videoWidth}px,  videoHeight: ${this.videoHeight}px`);
        });
        
        remoteVideo.onresize = () => {
            console.log(`Remote video size changed to ${remoteVideo.videoWidth}x${remoteVideo.videoHeight}`);
            // We'll use the first onsize callback as an indication that video has started
            // playing out.
            /*if (startTime) {
                const elapsedTime = window.performance.now() - startTime;
                console.log(`Setup time: ${elapsedTime.toFixed(3)}ms`);
                startTime = null;
            }*/
        };
          

        // Connect socket.io
        mySocket = io(process.env.VUE_APP_API_URL);
        mySocket.on('message', handleMessage);
        mySocket.on('connect', function() {
            currentPeer = createPeerConnection(remoteId);
            emitInfoIO('init', remoteId, null);
        });
    }

    sendMessageRTC(message, options) {
        // TODO currentPeer != null !!!
        sendMessage(message, options);
    }

    closeConnection() {
        streamEnd();
    }
}


// callback message received
function handleMessage(message) {
    try {
        var type = message.type;
        var from = message.from;

        switch (type) {
            case 'init':
                console.log("init");
            break;

            case 'offer':
                console.log("offer");

                currentPeer.setRemoteDescription(
                    new RTCSessionDescription(message.payload)
                );

                // TODO
                currentPeer.createAnswer().then(function(answer) {
                    currentPeer.setLocalDescription(answer);
                    emitInfoIO('answer', from, answer);
                  })
                  .catch(handleGetUserMediaError);
            break;

            case 'answer':
                currentPeer.setRemoteDescription(
                    new RTCSessionDescription(message.payload)
                );
            break;

            case 'candidate':
                if (currentPeer.remoteDescription) {
                    currentPeer.addIceCandidate(new RTCIceCandidate({
                        sdpMLineIndex: message.payload.label,
                        sdpMid: message.payload.id,
                        candidate: message.payload.candidate
                    }));
                }
            break;
        }
    }
    catch (error) {
        console.log(error);
    }
}

// Send info via socket.io
function emitInfoIO(type, to, payload) {
    mySocket.emit('message', {
        type: type,
        to: to,
        payload: payload
    });
}

// Create peer connection RTC
function createPeerConnection(remoteId) {
    var peerConnection = new RTCPeerConnection(config.peerConnectionConfig, config.peerConnectionConstraints);

    peerConnection.onicecandidate = function(event) {
        console.log("onicecandidate");

        if (event.candidate) {
            emitInfoIO('candidate', remoteId, {
                label: event.candidate.sdpMLineIndex,
                id: event.candidate.sdpMid,
                candidate: event.candidate.candidate
            });
            console.log("onicecandidateOK");
        }
    };

    peerConnection.ontrack  = function(event) {
        console.log("ontrack");
        console.log(event.streams[0]);
        
        remoteVideo.srcObject = event.streams[0];

        // Connect ok
        var message = { "action":"CONNECTED"};
        obs.fireEvent("myEvent", message);
    };

    peerConnection.onremovestream = function(event) {
        streamEnd();
    };

    peerConnection.oniceconnectionstatechange = function(event) {
        switch((event.srcElement || event.target).iceConnectionState) {
            case 'disconnected':
            streamEnd();
            break;
        }
    };

    // Data channel
    sendChannel = peerConnection.createDataChannel("DataChannelSecurityCameraNodeJS");
    sendChannel.onopen = handleSendChannelStatusChange;
    sendChannel.onclose = handleSendChannelStatusChange;

    peerConnection.ondatachannel = receiveChannelCallback;

    return peerConnection;
}

// Stream end - Close or disconnect...
function streamEnd() {
    console.log("streamEnd");

    mySocket.disconnect();
    mySocket.off();
    currentPeer.close();

    // Connect ok
    var message = { "action":"DISCONNECTED"};
    obs.fireEvent("myEvent", message);
}

function handleGetUserMediaError() {
    console.log("handleGetUserMediaError");
}

    

// Error general
function error(err) {
    // CallBack
}

// Data channel - Send message to phone
function sendMessage(action, options) {
    // TODO idConnectionViewer
    var message = { "action":action, "options":options, "idConnectionViewer":0};
    sendChannel.send(JSON.stringify(message));
}

// Handle status changes on the local end of the data
// channel; this is the end doing the sending of data
// in this example.
function handleSendChannelStatusChange(event) {
    if (sendChannel) {
        var state = sendChannel.readyState;
    }
}

// Called when the connection opens and the data
// channel is ready to be connected to the remote.
// TODO  Action.ID_CONNECTION_CAMERA_FOR_VIEWER...
function receiveChannelCallback(event) {
    receiveChannel = event.channel;

    receiveChannel.onmessage = function(event) {
        console.log("handleReceiveMessage " + event.data);
        console.log(event.data);

        let myDatas = JSON.parse(event.data);

        var message = { "action":myDatas.action, "options":myDatas.options};
        obs.fireEvent("myEvent", message);
    };

    receiveChannel.onopen =  function(event) {
        if (receiveChannel) {
            console.log("Receive channel's status has changed to " + receiveChannel.readyState);
        }
    };

    receiveChannel.onclose = function(event) {
        if (receiveChannel) {
            console.log("Receive channel's status has changed to " + receiveChannel.readyState);

            var message = { "action":"DISCONNECTED"};
            obs.fireEvent("myEvent", message);
        }
    };
}