export default class FunctionsClient {
    /** Take Picture
    takePicture(myVideoLive, canvas) {
        var width = 320;
        var height = myVideoLive.videoHeight / (myVideoLive.videoWidth/width);

        try {
            var context = canvas.getContext('2d');

            canvas.width = width;
            canvas.height = height;

            context.drawImage(myVideoLive, 0, 0, width, height);
            var data = canvas.toDataURL('image/png');

            // Download
            var a = document.createElement('a');
            a.style.display = 'none';
            a.href = data;
            a.target = '_blank';
            a.download = 'screen_' + this.getDateDownload() + '.png';
            document.body.appendChild(a);
            a.click();
            setTimeout(function() {
                document.body.removeChild(a);
            }, 100);
        }
        catch (error) {
            console.log(error);
        }
    }

    getDateDownload() {
        var datestring = "01";
        try {
            var d = new Date();
            datestring = d.getFullYear() + "-" + ("0" + d.getMonth()+1).slice(-2) + "-" + ("0"+(d.getDate())).slice(-2) + "-" +
            ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
        }
        catch (error) {
            console.log(error);
        }

        return datestring;
    } **/

    sendAction(action, options, clientPeer) {
        clientPeer.sendMessageRTC(action, options);
    }
}