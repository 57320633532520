import Vue from "vue";
import App from "./App.vue";
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import router from "./router";
import firebase from "firebase";
import store from "./store";
import Loading from "./components/lib/Loading";
import i18n from './i18/i18n'

const firebaseConfig = {
  apiKey: "AIzaSyB0hv1_C3_iUv2dMFZQ9QQKZ72QNOYb2t8",
  authDomain: "securitycamera-f3161.firebaseapp.com",
  databaseURL: "https://securitycamera-f3161.firebaseio.com",
  projectId: "securitycamera-f3161",
  storageBucket: "securitycamera-f3161.appspot.com",
  messagingSenderId: "420540713779",
  appId: "1:420540713779:web:1b6ca2e88eda080e20cc46",
  measurementId: "G-C279MGLWDE",
};

firebase.initializeApp(firebaseConfig);

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.component("loading", Loading);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,

  watch: {
    $route: function() {
      // Check if given route is true, if it is then hide Nav.
      if (this.$route.path === "/dashboard") {
        store.commit("SHOWNAV");
      } else {
        store.commit("HIDENAV");
      }
    },
  },
  render: (h) => h(App)
}).$mount("#app");
