<template>
  <div class="d-flex w-100 h-100 flex-column">
    <b-container>
      <b-row align-v="center">
        <b-col class="m-1">
          <h3>{{ $t("privacy-screen.title") }}</h3>
          <hr />

          <p>{{ $t("privacy-screen.intro_1") }}</p>
          <p>{{ $t("privacy-screen.intro_2") }}</p>
          <p>{{ $t("privacy-screen.intro_3") }}</p>

          <h5>{{ $t("privacy-screen.title_1") }}</h5>
          <p>{{ $t("privacy-screen.description_1_1") }}</p>
          <ul>
            <li>
              {{ $t("privacy-screen.description_1_2") }}
            </li>
            <li>
              {{ $t("privacy-screen.description_1_2_1") }}
            </li>
            <li>
              {{ $t("privacy-screen.description_1_2_2") }}
            </li>
          </ul>
          <p>{{ $t("privacy-screen.description_1_3") }}</p>
          <p>{{ $t("privacy-screen.description_1_4") }}</p>
          <p>{{ $t("privacy-screen.description_1_5") }}</p>
          <p>{{ $t("privacy-screen.description_1_6") }}</p>
          <p>{{ $t("privacy-screen.description_1_7") }}</p>
          <p>{{ $t("privacy-screen.description_1_9") }}</p>
          <p>{{ $t("privacy-screen.description_1_8") }}</p>

          <h5>{{ $t("privacy-screen.title_2") }}</h5>
          <p>{{ $t("privacy-screen.description_2_1") }}</p>
          <p>{{ $t("privacy-screen.description_2_2") }}</p>

          <h5>{{ $t("privacy-screen.title_3") }}</h5>
          <p>{{ $t("privacy-screen.description_3_1") }}</p>
          <p>{{ $t("privacy-screen.description_3_2") }}</p>
          <p>{{ $t("privacy-screen.description_3_3") }}</p>

          <h5>{{ $t("privacy-screen.title_4") }}</h5>
          <p>{{ $t("privacy-screen.description_4_1") }}</p>
          <p>{{ $t("privacy-screen.description_4_2") }}</p>

          <h5>{{ $t("privacy-screen.title_5") }}</h5>
          <p>{{ $t("privacy-screen.description_5_1") }}</p>
          <p>{{ $t("privacy-screen.description_5_2") }}</p>
          <p>{{ $t("privacy-screen.description_5_3") }}</p>

          <h5>{{ $t("privacy-screen.title_6") }}</h5>
          <p>{{ $t("privacy-screen.description_6_1") }}</p>
          <p>{{ $t("privacy-screen.description_6_2") }}</p>

          <h5>{{ $t("privacy-screen.title_7") }}</h5>
          <ul>
            <li>
              {{ $t("privacy-screen.description_7_1") }}
            </li>
            <li>
              {{ $t("privacy-screen.description_7_2") }}
            </li>
            <li>
              {{ $t("privacy-screen.description_7_3") }}
            </li>
            <li>
              {{ $t("privacy-screen.description_7_4") }}
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/footer/Footer";

export default {
  name: "Privacy Policy",
  components: {
    Footer,
  },
};
</script>

<style scoped>
.terms {
  background-color: #f8f8f8;
  border-bottom: 1px solid #f4f4f4;
}

h5 {
  margin-top: 2em;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
