<template>
  <b-navbar
    toggleable="lg"
    type="dark"
    variant="custom"
    v-show="!hideNavbarFooterApp"
  >
    <b-container>
      <b-navbar-brand :to="{ path: '/' }" class="align-item-center p-2">
        <!-- <lottie-animation
            path="animations/loading.json"
            :width="110"
            :height="110"
        />

        <div class="d-flex flex-row">
          <div>-->
            <b-img
              left
              :src="images.logoapp"
              fluid
              class="d-inline-block align-top"
              width="250"
              alt="Logo"
            ></b-img>
          <!--</div>
        </div>-->
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <li v-show="!isDashboardShow">
            <b-nav-item :to="{ path: '/' }">
              {{ $t("nav-screen.home") }}
            </b-nav-item>
          </li>

          <li v-show="!isDashboardShow" v-if="isAuthenticated">
            <b-nav-item :to="{ path: '/dashboard' }">
              {{ $t("nav-screen.my_account") }}
            </b-nav-item>
          </li>

          <li v-show="isDashboardShow" @click="logout">
            <b-nav-item>
              {{ $t("nav-screen.logout") }}
            </b-nav-item>
          </li>

          <!--<li v-if="!isAuthenticated">
            <b-nav-item :to="{ path: '/login' }">
              {{ $t("nav-screen.viewer") }}
            </b-nav-item>
          </li>-->

          <!--<li v-show="!isDashboardShow">
            <b-nav-item :to="{ path: '/about' }">
              À propos
            </b-nav-item>
          </li>-->
        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { AUTH_LOGOUT } from "../../store/actions/auth";
//import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  /*components: {
    LottieAnimation,
  },*/
  data() {
    return {
      images: {
        logoapp: require("@/assets/images/logo-mc.svg"),
      }
    };
  },
  methods: {
    logout: function() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push("/login"));
    },
  },
  computed: {
    ...mapGetters([
      "nameUser",
      "isAuthenticated",
      "isDashboardShow",
      "hideNavbarFooterApp",
    ]),
    /*...mapState({
      authLoading: (state) => state.auth.status === "loading",
    }),*/
  },
};
</script>

<style scoped src="@/assets/styles/customstyle.css"></style>
