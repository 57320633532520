import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;

const getUser = (idUserFirebase, refreshToken) =>
  new Promise((resolve, reject) => {
    const url = `${API_URL}/users/login`;

    axios.post(url, {idUserFirebase: idUserFirebase, refreshToken: refreshToken}).then((response) => {
      resolve(response.data);
    }, (err) => {
        reject(err);
    });
  });

const newUser = (mNewUser) =>
  new Promise((resolve, reject) => {
    const url = `${API_URL}/users/`;

    axios.post(url, mNewUser).then((response) => {
      resolve(response);
    }, (err) => {
        reject(err);
    });
  });

const getCamerasByUser = (idUser, token, refreshToken) =>
  new Promise((resolve, reject) => {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
      userId: idUser,
    };

    const url = `${API_URL}/users/${idUser}/cameras`;

    /*axios.get(url).then((response) => {
      resolve({responseData: response.data, idUser: idUser});
    }, (err) => {
        reject(err);
    });*/

    axios.get(url).then((response) => {
      resolve({responseData: response.data, idUser: idUser});
    }, (err) => {
       if (err.response.status === 401) {
          const url = `${API_URL}/refreshToken/`;

           axios.post(url,
               {
                refreshToken: refreshToken,
                userId: idUser
               })
               .then(res => {
                    console.log(res);

                   if (res.status === 200) {
                       // 1) put token to LocalStorage
                       localStorage.setItem("user-token", res.data.token);
                       localStorage.setItem("user-refresh-token", res.data.refreshToken);
    
                       // 2) Change Authorization header
                       axios.defaults.headers.common = {
                        Authorization: `Bearer ${res.data.token}`,
                        userId: idUser
                      };

                      const url = `${API_URL}/users/${idUser}/cameras`;
    
                       axios.get(url).then((response) => {
                        resolve({responseData: response.data, idUser: idUser});
                       }, (err) => {
                        reject(err);
                       });
                   }
               })
       }
       else {
        reject(err);
       }
    });
    
});

export default {
  getUser,
  getCamerasByUser,
  newUser
};
