<template>
  <b-container>
    <b-row class="justify-content-center m-2">
      <b-col cols="auto">
          GALLERY - {{this.$route.params.idCamera}}
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Gallery",

  data() {
    return {
      
    };
  },
  methods: {
  },
  mounted() {
  },
  beforeDestroy() {
    
  }
};
</script>

<style scoped>

</style>
