<template>
  <div class="d-flex w-100 h-100 flex-column">
    <LoginEmail/>
    <Footer/>
  </div>
</template>

<script>
import LoginEmail from '../components/login/LoginEmail'
import Footer from '../components/footer/Footer'

export default {
  name: 'LoginEmailView',
  components: {
    LoginEmail,
    Footer
  }
}
</script>