<template>
  <b-container>
    <div v-if="statusRequest">
      <div v-if="errorRequest">
        {{ dataResponseError }}
      </div>
      <div v-else>
        <div v-if="dataResponse.length">
          <b-row>
            <card-view-camera
              v-for="data in dataResponse"
              v-bind:key="data._id"
              v-bind:data="data"
            >
            </card-view-camera>
          </b-row>
        </div>
        <div v-else>
          <b-row class="justify-content-center mt-5">
            <b-col cols="auto">
              <h3 class="text-center">
                {{ $t("dashboard-screen.no_camera_last_step") }}
              </h3>
              <p class="text-center">
                {{ $t("dashboard-screen.no_camera_last_step_info") }}
              </p>
            </b-col>
          </b-row>

          <b-row class="justify-content-center">
            <b-col cols="auto">
              <div class="text-center mb-4">
                <b-button v-on:click="openApp" variant="link">
                  <b-img
                    :src="loadImgPlay()"
                    fluid
                    alt="Download app"
                    style="height:80px"
                  ></b-img>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>

    <div v-else>
      <loading />
    </div>
  </b-container>
</template>

<script>
import io from "socket.io-client";
import apiService from "../../service/APIService";
import CardViewCamera from "./CardViewCamera.vue";
import { mapGetters } from "vuex";
import { AUTH_LOGOUT } from "../../store/actions/auth";

export default {
  components: { CardViewCamera },
  name: "Dashboard",

  data() {
    return {
      statusRequest: false,
      errorRequest: false,
      dataResponse: [],
      dataResponseError: "",
      mIDUser: "",
      mySocket: null,
      images: {
        imgPlayStoreFR: require("@/assets/images/google-play-badge-fr.png"),
        imgPlayStoreEN: require("@/assets/images/google-play-badge-default.png"),
      },
      DELAY_PING: 30 * 1000, // 30 secondes
      mIDSocket: "",
      intervalPingAllCamera: null
    };
  },
  methods: {
    openApp() {
      window.open(
        "https://play.google.com/store/apps/details?id=com.mitch.camera",
        "_blank"
      );
    },
    loadImgPlay() {
      if (this.$i18n.t("images.lang") == "FR") {
        return this.images.imgPlayStoreFR;
      } else {
        return this.images.imgPlayStoreEN;
      }
    },
    /**
     * 
     * Ping camera
     * 
     * 
     */
    pingAllCamera() {
      console.log("pingAllCamera");
      let self = this;

      this.dataResponse.forEach(function (camera, index) {
        if (camera.tokenFluxCamera != null) {
            let messagePingCamera = { idSocketViewer: self.mIDSocket, idSocketCamera: camera.tokenFluxCamera };
            self.sendMesageSocketIO("pingCamera", messagePingCamera);

            camera.pingResponse = true;

            // IF no response
            setTimeout(function() {
              if ((camera.connectionProgress || camera.pingResponse) && camera.stateCamera) {
                  camera.stateCamera = false;
                  camera.connectionProgress = false;

                  let updateObject = Object.assign({}, camera, null);

                  self.$set(self.dataResponse, index, updateObject);
              }
            }, 10000);
        }
      });
    },
    /**
     *
     * SocketIO
     *
     */
    // Init SocketIO
    initSocketIO() {
      this.mySocket = io(process.env.VUE_APP_API_URL);
      this.mySocket.on("id", this.onId);
      this.mySocket.on("message", this.handleMessage);
      this.mySocket.on("pongViewer", this.pongViewer);
      this.mySocket.on("connect", function() {
        console.log("IO CONNECT");
      });
    },
    onId(IDSocket) {
      console.log("onId");
      console.log(IDSocket);

      this.mIDSocket = IDSocket;

      let message = { idSocket: this.mIDSocket, nameRoom: this.mIDUser };
      this.sendMesageSocketIO("joinRoom", message);

      // Ping all cameras all 30 seconds and check connection
      this.pingAllCamera();

      clearInterval(this.intervalPingAllCamera);
      this.intervalPingAllCamera = setInterval(() => {
          this.pingAllCamera();
      }, this.DELAY_PING);
    },
    handleMessage(message) {
      console.log("handleMessage");
      console.log(message);

      switch (message.typeAction) {
        case "CAMERA_UPDATE":
          {
            let mItemID = -1;
            let mNewData = JSON.parse(message.phoneObject);

            this.dataResponse.forEach((element) => {
              if (element._id === mNewData._id) {
                mItemID = this.dataResponse.indexOf(element);
                mNewData = Object.assign(element, mNewData);
              }
            });

            // Update
            if (mItemID != -1) {
              this.$set(this.dataResponse, mItemID, mNewData);
            }
            // New camera
            else {
              this.dataResponse.push(mNewData);
            }
          }
          break;
        case "CAMERA_UPDATE_INFO":
          {
            let mItemID = -1;
            let mNewData = JSON.parse(message.phoneObject);

            this.dataResponse.forEach((element) => {
              if (element._id === mNewData._id) {
                mItemID = this.dataResponse.indexOf(element);
                mNewData = Object.assign(element, mNewData);
              }
            });

            // Update
            if (mItemID != -1) {
              // Cas du switch
              if (mNewData.type == 1) {
                this.$delete(this.dataResponse, mItemID);
              } else {
                this.$set(this.dataResponse, mItemID, mNewData);
              }
            }
            // New camera
            else {
              this.dataResponse.push(mNewData);
            }
          }
          break;
        case "CAMERA_DELETED":
          {
            let mItemID = -1;
            let idCameraDelete = message.idCamera;

            this.dataResponse.forEach((element) => {
              if (element._id === idCameraDelete) {
                mItemID = this.dataResponse.indexOf(element);
              }
            });

            if (mItemID != -1) {
              this.$delete(this.dataResponse, mItemID);
            }
          }
          break;
        case "CAMERA_UPDATE_BATTERY":
        case "CAMERA_UPDATE_PREVIEW":
          {
            let mItemID = -1;
            let mNewData;

            this.dataResponse.forEach((element) => {
              if (element._id === message._id) {
                mItemID = this.dataResponse.indexOf(element);
                mNewData = Object.assign(element, message);
              }
            });

            if (mItemID != -1) {
              this.$set(this.dataResponse, mItemID, mNewData);
            }
          }
          break;
      }
    },
    pongViewer(message) {
      console.log("pongViewer");

      let self = this;

      this.dataResponse.forEach(function (camera, index) {
        if (camera.tokenFluxCamera == message.idSocketCamera) {
            // First pong
            if (camera.connectionProgress) {
                camera.connectionProgress = false;
                camera.pingResponse = false;
                camera.stateCamera = true;

                let updateObject = Object.assign({}, camera, null);

                self.$set(self.dataResponse, index, updateObject);
            }
            // Other ping/pong
            else if (camera.pingResponse) {
                camera.pingResponse = false

                if (!camera.stateCamera) {
                    camera.stateCamera = true

                    let updateObject = Object.assign({}, camera, null);

                    self.$set(self.dataResponse, index, updateObject);
                }
            }

            return;
        }
      });
    },
    sendMesageSocketIO(type, payload) {
      this.mySocket.emit(type, payload);
    },
  },
  computed: {
    ...mapGetters(["idUser", "token", "refreshToken"]),
    nonNullItems: function() {
      return this.items.filter(function(item) {
        return item !== null;
      });
    },
  },
  mounted() {
    apiService.getCamerasByUser(this.idUser, this.token, this.refreshToken).then(
      (response) => {
        this.statusRequest = true;

        this.mIDUser = response.idUser;
        this.dataResponse = response.responseData;

        this.dataResponse.forEach((camera) => {
          camera.connectionProgress = true
        });

        // Init SocketIO
        this.initSocketIO();
      },
      (err) => {
        if (err.response != null && err.response.status == 401) {
          this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push("/login"));
        }
        else {
          this.statusRequest = true;
          this.errorRequest = true;
          this.dataResponseError = err;
        }
      }
    );
  },
  beforeDestroy() {
    if (this.mySocket != null) {
      this.mySocket.disconnect();
      this.mySocket.off();
    }

    if (this.intervalPingAllCamera != null) {
      clearInterval(this.intervalPingAllCamera);
    }
  },
};
</script>
