<template>
  <div>
    <b-col cols="auto">
      <div class="card md-2 m-2" style="width: 18rem;">
        <b-img
              center
              @click="viewCamera(data)"
              v-if="data.previewImage !== undefined"
              class="card-img-top"
              :src="displayPreviewCamera"
              alt="preview camera"
        />

        <div class="card-body">
          <h6 class="card-title">
            <b-img
              :src="getStateCameraIcon"
              height="20px"
              alt="status camera"
            ></b-img>
            <span style="vertical-align: middle;" class="pl-2">{{
              this.nameCamera(data.name)
            }}</span>

            <span
              v-if="data.batteryLevel !== undefined"
              class="float-right text-muted"
              >{{ data.batteryLevel }}%</span
            >
          </h6>
        </div>

        <div class="card-footer">
          <small class="text-muted"
            >{{ this.format_date(data.localTime) }}
          </small>
          <br />
          <!--<b-button @click="viewGalleryCamera(data)" variant="primary">IMAGES</b-button>-->
        </div>
      </div>
    </b-col>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "CardViewCamera",
  props: ["data"],
  watch: {
    data(newValue, oldValue) {
      console.log("DATA CHANGE");
      console.log(newValue);
    },
  },
  data() {
    return {
      images: {
        cameraOnline: require("../../assets/images/camera_online.png"),
        cameraOffline: require("../../assets/images/camera_offline.png"),
        cameraLoad: require("../../assets/images/spinner-load-anim.gif"),
      },
    };
  },
  computed: {
    displayPreviewCamera: function() {
      /*if (this.data.connectionProgress) {
        return this.images.cameraLoad;
      }
      else {
        return 'data:image/jpeg;base64,' + this.data.previewImage;
      }*/
      return 'data:image/jpeg;base64,' + this.data.previewImage;
    },
    getStateCameraIcon: function() {
      if (this.data.statusCamera) {
        if (this.data.stateCamera) {
          if (this.data.connectionProgress) {
            return this.images.cameraLoad;
          } else {
            return this.images.cameraOnline;
          }
        } else {
          return this.images.cameraOffline;
        }
      } else {
        return this.images.cameraOffline;
      }
    },
    getStateCamera: function() {
      if (this.data.statusCamera) {
        if (this.data.stateCamera) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  methods: {
    viewGalleryCamera: function(mCamera) {
      this.$router.push({ name: "Gallery", params: { idCamera: mCamera._id } });
    },
    viewCamera: function(mCamera) {
      if (!mCamera.connectionProgress && mCamera.statusCamera && mCamera.stateCamera) {
        this.$router.push({
          name: "Viewer",
          params: { tokenFluxCamera: mCamera.tokenFluxCamera },
        });
      }
      else if (!mCamera.connectionProgress) {
        this.$bvToast.toast("La caméra n'est pas en ligne.", {
          title: "Hors-ligne",
          autoHideDelay: 5000,
          variant: "danger",
          solid: true,
          toaster: "b-toaster-bottom-right",
          appendToast: true,
        });
      }
    },
    format_date(value) {
      if (value) {
        return moment(value, "YYYY_MM_DD_HH_mm_ss_SSS").format(
          "DD/MM/yyyy HH:mm:ss"
        );
      }
    },
    nameCamera: function(nameCamera) {
      if (this.data.statusCamera) {
        return nameCamera;
      } else {
        return nameCamera + " (OFF)";
      }
    },
  },
};
</script>

<style scoped>
/*.card-body:hover{
    box-shadow: 8px 8px 8px;
    transform:scale(1.05);
    cursor: pointer;
}
.card-img-top:hover{
    box-shadow: 8px 8px 8px;
    transform:scale(1.05);
    cursor: pointer;
}*/

.card-img-top {
    width: 100%;
    height: 20rem;
    object-fit: cover;
}
</style>
