import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Terms from '../views/Terms.vue'
import PrivacyPolicy from '../views/Privacy-policy.vue'
import Login from '../views/Login.vue'
import LoginEmail from '../views/Login-email.vue'
import Dashboard from '../views/Dashboard.vue'
import Viewer from '../views/Viewer.vue'
import Gallery from '../views/Gallery.vue'
import store from "../store";

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/login");
};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/privacy-policy',
    name: 'Privacy policy',
    component: PrivacyPolicy
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/login-email',
    name: 'LoginEmail',
    component: LoginEmail,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/dashboard/',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/viewer/:tokenFluxCamera',
    name: 'Viewer',
    component: Viewer,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/gallery/:idCamera',
    name: 'Gallery',
    component: Gallery,
    beforeEnter: ifAuthenticated
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
