<template>
  <div class="d-flex w-100 h-100 flex-column">
    <HomeLandingPage/>
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import HomeLandingPage from '../components/home/HomeLandingPage'
import Footer from '../components/footer/Footer'

export default {
  name: 'Home',
  components: {
    HomeLandingPage,
    Footer
  }
}
</script>