<template>
  <div class="d-flex w-100 h-100 flex-column">
    <b-container>
      <b-row align-v="center">
        <b-col class="terms m-1">

          <h3>{{ $t('terms-screen.title') }}</h3>
          <hr/>

          <p>
            {{ $t('terms-screen.last_date') }}
          </p>
          <h5>1. {{ $t('terms-screen.title_1') }}</h5>
          <p>
            {{ $t('terms-screen.description_1_1') }}
          </p> 
          <p>
            {{ $t('terms-screen.description_1_2') }}
          </p>
          <p>
            {{ $t('terms-screen.description_1_3') }}
          </p>

          <h5>2. {{ $t('terms-screen.title_2') }}</h5>
          <p>
            {{ $t('terms-screen.description_2_1') }}
          </p>
          <p>
            {{ $t('terms-screen.description_2_2') }}
          </p>

          <h5>3. {{ $t('terms-screen.title_3') }}</h5>
          <p>
             {{ $t('terms-screen.description_3_1') }}
             </p>
          <p>
           {{ $t('terms-screen.description_3_2') }}
             </p>
          <p>
            {{ $t('terms-screen.description_3_3') }}
             </p>
          <p>
            {{ $t('terms-screen.description_3_4') }}
             </p>
          <p>
            {{ $t('terms-screen.description_3_5') }}
            </p>
          <p>
            {{ $t('terms-screen.description_3_6') }}
             </p>
          <p>
            {{ $t('terms-screen.description_3_7') }}
            </p>
          <p>
            {{ $t('terms-screen.description_3_8') }}
            </p>
          <p>
           {{ $t('terms-screen.description_3_9') }}
          </p>

          <h5>4. {{ $t('terms-screen.title_4') }}</h5>
          <p>
            {{ $t('terms-screen.description_4_1') }}
             </p>
<p>
            {{ $t('terms-screen.description_4_2') }}
             </p>
<p>
           {{ $t('terms-screen.description_4_3') }}
             </p>
<p>
            {{ $t('terms-screen.description_4_4') }}
             </p>
<p>
            {{ $t('terms-screen.description_4_5') }}
             </p>

          <h5>5. {{ $t('terms-screen.title_5') }}</h5>
          <p>a) 	{{ $t('terms-screen.subtitle_5_1') }}</p>
          <p>
            {{ $t('terms-screen.description_5_1_1') }}
             </p>
<p>
           <ul>
             <li>
               {{ $t('terms-screen.description_5_1_2') }}
             </li>
             <li>{{ $t('terms-screen.description_5_1_3') }}</li>
             <li>{{ $t('terms-screen.description_5_1_4') }}</li>

           </ul>
          </p>

           <p>b) 	{{ $t('terms-screen.subtitle_5_2') }}</p>

           <p>{{ $t('terms-screen.description_5_2_1') }}</p>
           <p>{{ $t('terms-screen.description_5_2_2') }}</p>
           <p>{{ $t('terms-screen.description_5_2_3') }}</p>
           <p>{{ $t('terms-screen.description_5_2_4') }}</p>


<h5>6. {{ $t('terms-screen.title_6') }}</h5>
<p>{{ $t('terms-screen.description_6_1') }}</p>

<ul>
  <li>
    {{ $t('terms-screen.description_6_2') }}
  </li>
  <li>
    {{ $t('terms-screen.description_6_3') }}
  </li>
  <li>
    {{ $t('terms-screen.description_6_4') }}
  </li>
  <li>
    {{ $t('terms-screen.description_6_5') }}
  </li>
  <li>
    {{ $t('terms-screen.description_6_6') }}
  </li>
  <li>
    {{ $t('terms-screen.description_6_7') }}
  </li>
  <li>
    {{ $t('terms-screen.description_6_8') }}
  </li>
  <li>
    {{ $t('terms-screen.description_6_9') }}
  </li>

</ul>

<!--
<h5>7. PRIX ET CONDITIONS DE PAIEMENT (SERVICES PAYANTS)</h5>

<p>Le téléchargement et l’installation de l’Application est consentie à titre gratuit à l’Utilisateur par l'Éditeur. Sous réserve d’avoir accepté les Conditions Particulières proposées par l'Éditeur, l’Utilisateur bénéficie toutefois de la possibilité d’accéder à des Services particuliers payants.
</p>

<p>Le prix total de la commande de l’Utilisateur est indiqué au sein du descriptif de l’offre sélectionnée par l’Utilisateur sur le site de l'Éditeur, avant que l’Utilisateur valide sa commande et procède au paiement. Ce montant total est indiqué en euros toutes taxes comprises.
</p>

<p>Ces prix peuvent être modifiés à tout moment par l'Éditeur, les prix affichés ne sont valables qu’au jour de la souscription et ne portent pas effet pour l’avenir. Les prix communiqués ne comprennent pas le coût nécessaire à l’accès au réseau internet par le terminal mobile et l’ordinateur de l’Utilisateur.
</p>

<p>L’Utilisateur garantit à l'Éditeur qu’il dispose des autorisations nécessaires pour utiliser le mode de paiement sélectionné lors de la passation de commande de Services payants. Après validation de la commande avec obligation de paiement, le contrat est valablement conclu entre l'Éditeur et l’Utilisateur et les engage de manière irrévocable.
</p>

<p>L’Éditeur se réserve le droit de suspendre tout accès à l’Application, au Site et/ou aux Services en cas de défaut de paiement ou de paiement partiel de toute somme qui serait due par l’Utilisateur à l’Éditeur, en cas d’incident de paiement, ou en cas de fraude ou tentative de fraude.
</p>

<h5>8. POLITIQUE DE REMBOURSEMENT</h5>
<p>Veuillez vous assurer de bien lire la politique de remboursement avant de compléter votre commande, vous pouvez en prendre connaissance en cliquant ici.</p>
-->

<h5>7. {{ $t('terms-screen.title_7') }}</h5>

<p>{{ $t('terms-screen.description_7_1') }}</p>
<p>{{ $t('terms-screen.description_7_2') }}</p>
<p>{{ $t('terms-screen.description_7_3') }}</p>
<p>{{ $t('terms-screen.description_7_4') }}</p>

<h5>8. {{ $t('terms-screen.title_8') }}</h5>

<p>{{ $t('terms-screen.description_8_1') }}</p>
<p>{{ $t('terms-screen.description_8_2') }}</p>

<ul>
<li>
{{ $t('terms-screen.description_8_3') }}
</li>
<li>
{{ $t('terms-screen.description_8_4') }}
</li>
<li>
{{ $t('terms-screen.description_8_5') }}
</li>
</ul>

<h5>9. {{ $t('terms-screen.title_9') }}</h5>
<p>{{ $t('terms-screen.description_9_1') }}</p>
<p>{{ $t('terms-screen.description_9_2') }}</p>
<p>{{ $t('terms-screen.description_9_3') }}</p>
<p>{{ $t('terms-screen.description_9_4') }}</p>

<ul>
  <li>
  {{ $t('terms-screen.description_9_5') }}
  </li>
  <li>
  {{ $t('terms-screen.description_9_6') }}
  </li>
  <li>
  {{ $t('terms-screen.description_9_7') }}
  </li>
</ul>

<h5>10. {{ $t('terms-screen.title_10') }}</h5>

<p>{{ $t('terms-screen.description_10_1') }}</p>

<ul>
  <li>
  {{ $t('terms-screen.description_10_2') }}
  </li>
  <li>
  {{ $t('terms-screen.description_10_3') }}
  </li>
  <li>
  {{ $t('terms-screen.description_10_4') }}
  </li>
  <li>
  {{ $t('terms-screen.description_10_5') }}
  </li>
  <li>
  {{ $t('terms-screen.description_10_6') }}
  </li>
  <li>
  {{ $t('terms-screen.description_10_7') }}
  </li>
  <li>
  {{ $t('terms-screen.description_10_8') }}
  </li>
  <li>
  {{ $t('terms-screen.description_10_9') }}
  </li>
  <li>
  {{ $t('terms-screen.description_10_10') }}
  </li>
</ul>




<h5>11. {{ $t('terms-screen.title_11') }}</h5>

<p>{{ $t('terms-screen.description_11_1') }}</p>
<p>{{ $t('terms-screen.description_11_2') }}</p>
<p>{{ $t('terms-screen.description_11_3') }}</p>

<h5>12. {{ $t('terms-screen.title_12') }}</h5>

<p>{{ $t('terms-screen.description_12_1') }}</p>
<p>{{ $t('terms-screen.description_12_2') }}</p>

<h5>13. {{ $t('terms-screen.title_13') }}</h5>

<p>{{ $t('terms-screen.description_13_1') }}</p>

<p>{{ $t('terms-screen.description_13_2') }}<router-link id="link-footer" to="/privacy-policy"> {{ $t('terms-screen.description_13_3') }}</router-link> {{ $t('terms-screen.description_13_4') }}</p>

<h5>14. {{ $t('terms-screen.title_14') }}</h5>

<p>{{ $t('terms-screen.description_14_1') }}</p>
<p>{{ $t('terms-screen.description_14_2') }}</p>

<h5>15. {{ $t('terms-screen.title_15') }}</h5>
<p>{{ $t('terms-screen.description_15_1') }}</p>


<h5>16. {{ $t('terms-screen.title_16') }}</h5>

<p>{{ $t('terms-screen.description_16_1') }}</p>
<p>{{ $t('terms-screen.description_16_2') }}</p>
<p>{{ $t('terms-screen.description_16_3') }}</p>
<p>{{ $t('terms-screen.description_16_4') }}</p>

        </b-col>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/footer/Footer";

export default {
  name: "Terms",
  components: {
    Footer,
  },
};
</script>

<style>
/*.terms {
    color: #f8f8f8;
    border-bottom: 1px solid #f4f4f4;
  }*/

h5 {
  margin-top: 2em;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
