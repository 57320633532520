<template>
  <b-container>
    <!-- autoplay not work if no user interaction 
    <center>
        <span id="playBtnInfo" class="play" style="text-align: center; color: red; display:none;">
            <b>Info: You need to click the Play button to start the video.</b>
        </span>

        <button id="playBTNManuel" class="play" style="display:none;" onClick="goVideoPlay(myVideoLive)">
            <img src="img/play-circle-solid.svg" alt="play" height="25px" />
        </button>
    </center>-->

    <div v-if="!isConnected">
      <b-row class="justify-content-center m-5">
        <b-col cols="auto">
          <loading />
          <p>
            <strong v-show="errorConnection"
              >Connexion impossible. Redirection en cours...</strong
            >
          </p>
          <p>
            <strong v-show="errorOccured"
              >Vous avez été déconnecté de la caméra...</strong
            >
          </p>
        </b-col>
      </b-row>
    </div>

    <b-row class="justify-content-center">
      <b-col cols="auto">
        <!-- Contains video -->
        <div class="embed-responsive">
          <video
            id="myVideoLive"
            ref="myVideoLive"
            controls
            autoplay
            v-show="isConnected"
            playsinline
            webkit-playsinline
          />
        </div>

        <!-- Contains video
        <b-embed
          id="myVideoLive"
          ref="myVideoLive"
          type="video"
          aspect="4by3"
          controls
          autoplay
          v-show="isConnected"
          playsinline
          webkit-playsinline
        ></b-embed> -->
        <canvas id="canvas" ref="canvas" style="display:none;"></canvas>
      </b-col>
    </b-row>

    <b-row class="justify-content-center m-2">
      <b-col cols="auto">
        <b-button
          id="takePicture"
          @click="takePicture('TAKE_PICTURE', null)"
          class="m-2"
          v-show="isConnected"
          :disabled="actionProgress"
          variant="primary"
        >
          <b-icon icon="camera" aria-hidden="true"></b-icon> Take
          Picture
        </b-button>

        <b-button
          id="recordVideo"
          @click="recordVideo('RECORD_VIDEO', null)"
          class="m-2"
          v-show="isConnected"
          :disabled="actionProgressRecordVideo"
          variant="primary"
        >
          <b-icon icon="record-btn" aria-hidden="true"></b-icon>
          Record Video
        </b-button>

        <b-button
          id="actionSwitch"
          @click="sendAction('SWITCH', null)"
          class="m-2"
          v-show="isConnected"
          :disabled="actionProgress"
          variant="primary"
        >
          <b-icon icon="arrow-left-right" aria-hidden="true"></b-icon>
          {{ $t("viewer-screen.action_switch") }}
        </b-button>
        <!--<b-button id="actionZoom" @click="sendAction('ZOOM', null)" class="m-2" v-show="isConnected" variant="primary">ZOOM</b-button>-->
        <!--<b-button
          id="actionFlash"
          @click="sendAction('FLASH', true)"
          class="m-2"
          v-show="isConnected"
          variant="primary"
          >Flash</b-button>-->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import PeerConnectionClient from "../../service/PeerConnectionClient";
import Observable from "../../service/Observable";
import FunctionsClient from "../../service/FunctionsClient";
import apiService from "../../service/APIService";
import { mapGetters } from "vuex";
import { AUTH_LOGOUT } from "../../store/actions/auth";

export default {
  name: "Viewer",

  data() {
    return {
      isConnected: false,
      myPeerConnection: PeerConnectionClient,
      myFunctionsClient: FunctionsClient,
      errorConnection: false,
      errorOccured: false,
      timeoutConnection: null,
      actionProgress: false,
      actionProgressRecordVideo: false
    };
  },

  methods: {
    startViewer(tokenFluxCamera) {
      let self = this;

      var obs = new Observable();
      obs.listen("myEvent", function(observable, eventType, data) {
        switch (data.action) {
          case "CONNECTED":
            self.isConnected = true;
            break;
          case "DISCONNECTED":
            self.isConnected = false;

            self.errorOccured = true;
            break;
          case "TAKE_PICTURE": {
            let messagePicture = "";
            let autoHide = false;

            switch (data.options) {
              case "IN_PROGRESS": {
                messagePicture = "Prise de la photo en cours...";
                break;
              }
              case "SAVE_WS_IN_PROGRESS": {
                messagePicture = "Envoi de la photo en cours...";
                break;
              }
              case "SAVE_WS_OK": {
                autoHide = true;
                messagePicture = "La photo a été sauvegardée avec succès.";
                self.actionProgress = false;
                self.actionProgressRecordVideo = false;
                break;
              }
              case "SAVE_WS_KO": {
                autoHide = true;
                messagePicture = "Une erreur est survenue lors de la prise de la photo.";
                self.actionProgress = false;
                self.actionProgressRecordVideo = false;
                break;
              }
              case "ERROR": {
                autoHide = true;
                messagePicture = "Une erreur est survenue lors de la prise de la photo.";
                self.actionProgress = false;
                self.actionProgressRecordVideo = false;
                break;
              }
            }

            self.$bvToast.toast(messagePicture, {
              id: "TAKE_PICTURE",
              title: "Prise de photo",
              autoHideDelay: 8000,
              noAutoHide: !autoHide,
              //noCloseButton: true,
              variant: "info",
              solid: true,
              toaster: "b-toaster-bottom-right",
              appendToast: true,
            });

            self.$bvToast.hide();
            break;
          }
          case "RECORD_VIDEO": {
            let messagePicture = "";
            let autoHide = false;
            let closeButton = true;

            switch (data.options) {
              case "INIT_VIDEO_OK": {
                messagePicture = "Enregistrement en cours (30 secondes)...";
                closeButton = false;
                break;
              }
              case "SAVE_WS_IN_PROGRESS": {
                messagePicture = "Envoi de l'enregistrement en cours...";
                break;
              }
              case "SAVE_WS_OK": {
                autoHide = true;
                messagePicture = "L'enregistrement a été sauvegardé avec succès.";
                self.actionProgress = false;
                self.actionProgressRecordVideo = false;
                break;
              }
              case "SAVE_WS_KO": {
                autoHide = true;
                messagePicture = "Une erreur est survenue lors de l'enregistrement.";
                self.actionProgress = false;
                self.actionProgressRecordVideo = false;
                break;
              }
              case "ERROR": {
                autoHide = true;
                messagePicture = "Une erreur est survenue lors de l'enregistrement.";
                self.actionProgress = false;
                self.actionProgressRecordVideo = false;
                break;
              }
              case "PREPARE_VIDEO_RELEASE": {
                messagePicture = "Sauvegarde en cours...";
                break;
              }
            }

            self.$bvToast.toast(messagePicture, {
              id: "RECORD_VIDEO",
              title: "Prise de vidéo",
              autoHideDelay: 8000,
              noAutoHide: !autoHide,
              noCloseButton: !closeButton,
              variant: "info",
              solid: true,
              toaster: "b-toaster-bottom-right",
              appendToast: true,
            });

            self.$bvToast.hide();
            break;
          }
          case "INFO_CAMERA_TO_VIEWER": {
            self.actionProgress = false;
            self.actionProgressRecordVideo = false;
            break;
          }
          case "ID_CONNECTION_CAMERA_FOR_VIEWER": {
            
            break;
          }
        }
      });

      this.myPeerConnection = new PeerConnectionClient(
        process.env.VUE_APP_API_URL,
        this.$refs["myVideoLive"],
        tokenFluxCamera,
        obs
      );

      this.myFunctionsClient = new FunctionsClient();

      // Tiemout connection
      this.timeoutConnection = setTimeout(function() {
        if (!self.isConnected) {
          self.errorConnection = true;

          setTimeout(function() {
            self.$router.go(-1);
          }, 3000);
        }
      }, 6000);
    },

    takePicture(nameAction, options) {
      this.$bvToast.toast("Demande de photo en cours...", {
        id: "TAKE_PICTURE",
        title: "Prise de photo",
        noAutoHide: true,
        //noCloseButton: true,
        variant: "info",
        solid: true,
        toaster: "b-toaster-bottom-right",
        appendToast: true,
      });

      this.actionProgress = true;
      this.actionProgressRecordVideo = true;
      this.myPeerConnection.sendMessageRTC(nameAction, options);
    },

    recordVideo(nameAction, options) {
      // TODO Change button for add STOP...
      this.$bvToast.toast("Demande d'enregistrement en cours...", {
        id: "RECORD_VIDEO",
        title: "Prise de vidéo",
        noAutoHide: true,
        variant: "info",
        solid: true,
        toaster: "b-toaster-bottom-right",
        appendToast: true,
      });

      this.actionProgress = true;
      this.actionProgressRecordVideo = true;
      this.myPeerConnection.sendMessageRTC(nameAction, options);
    },

    sendAction(nameAction, options) {
      this.actionProgress = true;
      this.actionProgressRecordVideo = true;
      this.myPeerConnection.sendMessageRTC(nameAction, options);
    },
  },

  computed: {
    ...mapGetters(["idUser", "token", "refreshToken"]),
  },

  mounted() {
    var self = this;
    let findCamera = false;
    let tokenFluxCameraViewer = this.$route.params.tokenFluxCamera;

    // Check if ID Token is a camera of the account !!!
    apiService
      .getCamerasByUser(this.idUser, this.token, this.refreshToken)
      .then(
        (response) => {
          response.responseData.forEach((camera) => {
            if (camera.tokenFluxCamera == tokenFluxCameraViewer) {
              findCamera = true;
              return;
            }
          });

          if (findCamera) {
            self.startViewer(self.$route.params.tokenFluxCamera);
          } else {
            this.$store
              .dispatch(AUTH_LOGOUT)
              .then(() => this.$router.push("/login"));
          }
        },
        (err) => {
          this.$store
            .dispatch(AUTH_LOGOUT)
            .then(() => this.$router.push("/login"));
        }
      );
  },

  beforeDestroy() {
    clearTimeout(this.timeoutConnection);
    this.myPeerConnection.closeConnection();
  },
};
</script>

<style scoped>
.embed-responsive video {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  max-height: 640px;
}
</style>
