<template>
  <div id="app" class="d-flex w-100 h-100 mx-auto flex-column">
    <Navbar />
    <router-view />
  </div>
</template>
<script>
require("@/assets/styles/customstyle.css");

import Navbar from "./components/navigation/Navbar";
import store from "./store";

export default {
  name: "App",
  components: {
    Navbar,
  },
  created: function() {
    // TODO Check if token is always valid !!!
    if (this.$store.getters.isAuthenticated) {
      console.log("Connected");
    }
    else {
      console.log("No connected");
    }

    // Change navbar if connected
    if (this.$route.path === "/dashboard") {
        store.commit("SHOWNAV");
    } else {
      store.commit("HIDENAV");
    }

    // Hide navbar & footer if displayed from app
    if (this.$route.fullPath === "/terms?app") {
        store.commit("ISAPP");
    }

    if (this.$route.fullPath === "/privacy-policy?app") {
        store.commit("ISAPP");
    }
  },
};
</script>
