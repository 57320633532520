<template>
  <div class="d-flex w-100 h-100 flex-column">
    <Dashboard/>
    <Footer/>
  </div>
</template>

<script>
import Footer from '../components/footer/Footer'
import Dashboard from '../components/dashboard/Dashboard'

export default {
  name: 'DashboardView',
  components: {
    Dashboard,
    Footer
  }
}
</script>