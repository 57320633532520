<template>
  <b-container>
    
    <b-row class="d-flex justify-content-center mt-5">
      <b-col cols="auto">
        {{ error }}
      </b-col>
    </b-row>
    
    <b-row class="d-flex justify-content-center mt-5">
      <b-col cols="auto">
        <b-form @submit="login" v-if="show">
          <b-form-group
            id="input-group-1"
            label="Email"
            label-for="input-1" >
            <b-input-group class="mb-2">
                <b-input-group-prepend is-text>
                    <b-icon icon="envelope-fill"></b-icon>
                </b-input-group-prepend>

                <b-form-input
                  id="input-1"
                  v-model="form.email"
                  type="email"
                  placeholder="Email"
                  required/>
            </b-input-group>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label="Password"
            label-for="input-2">
            <b-input-group class="mb-2">
                <b-input-group-prepend is-text>
                    <b-icon icon="key-fill"></b-icon>
                </b-input-group-prepend>

                <b-form-input
                  id="input-2"
                  v-model="form.password"
                  type="password"
                  placeholder="Password"
                  required/>
            </b-input-group>
          </b-form-group>

          <div class="d-flex justify-content-end mt-2">
            <b-button type="submit" variant="primary" :disabled="isDisabled" class="mb-2">
              <b-icon icon="box-arrow-right" aria-hidden="true"></b-icon> Login
            </b-button>
          </div>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { AUTH_REQUEST_EMAIL_PASSWORD } from "../../store/actions/auth";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      error: '',
      form: {
        email: '',
        password: ''
      },
      show: true,
      isDisabled: false
    };
  },
  computed: {
    ...mapGetters(["authStatus"])
  },
  methods: {
    login: function(event) {
      event.preventDefault();
      this.isDisabled = true;

      this.error = '';

      let email = this.form.email;
      let password = this.form.password;
      
      this.$store
        .dispatch(AUTH_REQUEST_EMAIL_PASSWORD, {email, password})
        .then(() => {
          this.$router.push({ name: "Dashboard" });
        })
        .catch((err) => {
          this.isDisabled = false;

          var errorCode = err.code;
          var errorMessage = err.message;

          // TODO Custom error
          switch (errorCode) {
            case "auth/invalid-email": 
              break;
            case "auth/user-disabled": 
              break;
            case "auth/user-not-found": 
              break;
            case "auth/wrong-password": 
              break;
            case 400:
              // TOO_MANY_ATTEMPTS_TRY_LATER
              // Display err.message
              break;
            default:
              break;
          }

          this.error = errorMessage;
        });
    },
  },
};
</script>
