<template>
  <b-container>
    <b-row class="justify-content-center mt-5">
      <b-col cols="auto text-center">
        <h4 class="mb-4">{{ $t('login-screen.message_1') }}</h4>
        <p>{{ $t('login-screen.message_2') }}</p>
      </b-col>
    </b-row>

    <b-row class="justify-content-center mt-5">
      <b-col cols="auto">
        <b-link
          class="btn btn-light"
          @click="login"
          role="button"
          style="text-transform:none"
        >
          <img
            width="20px"
            style="margin-bottom:3px; margin-right:5px"
            alt="Google sign-in"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
          />
         {{ $t('login-screen.login') }}
        </b-link>
      </b-col>
    </b-row>

    <b-row class="justify-content-center mt-5">
      <b-col cols="auto">
        <b-button variant="primary" class="mb-6" @click="$router.push('login-email')">
          <b-icon icon="box-arrow-right" aria-hidden="true"></b-icon> Login with Email
        </b-button>
      </b-col>
    </b-row>

    <b-row class="justify-content-center mt-5">
      <b-col cols="auto">
        <loading v-if="loading" />
        {{ error }}
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { AUTH_REQUEST } from "../../store/actions/auth";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      error: ''
    };
  },
  computed: {
    ...mapGetters(["authStatus"]),
    loading: function() {
      return this.authStatus === "loading" && !this.isAuthenticated;
    },
  },
  methods: {
    login: function() {
      this.error = '';
      
      this.$store
        .dispatch(AUTH_REQUEST) 
        .then(() => {
          this.$router.push({ name: "Dashboard" });
        })
        .catch((err) => {
          if (err == "NO_USER") {
              this.error = this.$i18n.t('login-screen.no_user');
          }
          else {
            this.error = err
          }
        });
    },
  },
};
</script>
