<template>
  <div class="d-flex w-100 h-100 flex-column">
    <Viewer/>
    <Footer/>
  </div>
</template>

<script>
import Footer from '../components/footer/Footer'
import Viewer from '../components/dashboard/Viewer'

export default {
  name: 'ViewerVue',
  components: {
    Viewer,
    Footer
  }
}
</script>