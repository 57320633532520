<template>
  <div class="d-flex w-100 h-100 flex-column">
    <Login/>
    <Footer/>
  </div>
</template>

<script>
import Login from '../components/login/Login'
import Footer from '../components/footer/Footer'

export default {
  name: 'LoginView',
  components: {
    Login,
    Footer
  }
}
</script>